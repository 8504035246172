import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (localStorage.getItem(`notice-dismissed-${this.element.id}`)) {
      this.element.remove()
    }
  }

  close() {
    localStorage.setItem(`notice-dismissed-${this.element.id}`, true)
    this.element.remove()
  }
}
