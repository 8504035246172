import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['backButton', 'submit']

  updateBackButtonHref(event) {
    const { params } = event
    console.log(params)
    document.cookie = `preview=${params.type};path=/`
  }

  submit(e) {
    if(document.querySelectorAll("[data-uploading]").length > 0) {
      this.showToast({
        message: this.translations.compose.submit.blocked_by_attachments_uploading,
      })

      e.preventDefault()
      e.stopPropagation()
    }

    if(document.querySelectorAll("[data-upload-error]").length > 0) {
      this.showToast({
        message: this.translations.compose.submit.blocked_by_uploaded_attachment_error,
      })


      e.preventDefault()
      e.stopPropagation()
    }
  }

  sync({ detail: composeSnapshot }) {
    this.submitTarget.disabled = composeSnapshot.string.length === 0 || composeSnapshot.overflow

    if(composeSnapshot.overflow) {
      this.showToast({
        message: t.compose.submit.blocked_by_overflow.replace('%{max_length}', composeSnapshot.technology.max_message_length),
        type: 'error',
      })
    }
  }
}
