import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["notice"]

  async connect() {
    await this.registerServiceWorker()
    await this.checkSubscriptionAndSendIfExists()
  }

  async requestPermission() {
    if ("Notification" in window) {
      const permission = await Notification.requestPermission()
      if (permission === "granted") {
        await this.createSubscriptionAndSend()
        this.listenForServiceWorkerMessages()
        this.noticeTarget.remove()
      }
    } else {
      console.warn("Push notifications not supported.")
    }
  }

  async registerServiceWorker() {
    if ('serviceWorker' in navigator) {
      try {
        await navigator.serviceWorker.register('/service_worker.js')
      } catch (error) {
        console.error('Service Worker registration failed:', error)
      }
    } else {
      console.warn('Service Workers are not supported in this browser.')
    }
  }

  async checkSubscriptionAndSendIfExists() {
    const subscription = await this.getSubscription()
    if (subscription) {
      await this.sendSubscriptionToServer(subscription)
    }
  }

  async createSubscriptionAndSend() {
    let subscription = await this.getSubscription()

    if (!subscription) {
      subscription = await this.subscribeToPushManager()
    }

    if (subscription) {
      await this.sendSubscriptionToServer(subscription)
    }
  }

  async getSubscription() {
    if ('serviceWorker' in navigator) {
      const sw = await navigator.serviceWorker.ready
      return await sw.pushManager.getSubscription()
    }
    return null
  }

  async subscribeToPushManager() {
    if ('serviceWorker' in navigator) {
      const sw = await navigator.serviceWorker.ready
      return await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: window.vapidPublicKey
      })
    }
    return null
  }

  async sendSubscriptionToServer(subscription) {
    try {
      await post('/push_subscriptions', {
        body: JSON.stringify({ subscription })
      })
    } catch (error) {
      console.error('Failed to send subscription to server:', error)
    }
  }

  listenForServiceWorkerMessages() {
    navigator.serviceWorker.addEventListener('message', this.handleServiceWorkerMessage.bind(this))
  }

  handleServiceWorkerMessage() {
    const audio = new Audio('/notification-sound.mp3')
    audio.play()
  }

  noticeTargetConnected() {
    if ("Notification" in window) {
      if (Notification.permission === "granted" || Notification.permission === "denied") {
        this.noticeTarget.remove()
      }
    } else {
      this.noticeTarget.remove()
    }
  }
}
